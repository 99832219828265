// import axios from 'axios';
import { hanatourApi } from '../../main.js';
export default class ReportedUsersService {

    async getreportedUsersList(obj) {
        var params = new URLSearchParams(obj).toString();
        return await hanatourApi.get(`admin/report/report-user-list?${params}`)
            .then((res) => res)
            .catch((err) => err)

    }
    async getReportedUsersDetails(id) {
        return await hanatourApi.get(`admin/report/user-report-details/${id}`)
        .then((res) => res)
        .catch((err) => err)
    }

}